<template>
    <div class="home-footer">
    <footer class="home-container21">
      <img alt="image" src="/img/logo2-1200w.png" class="home-image10" />
      <div class="home-container22">
        <span class="home-text33" @click="scrollToAbout()">About</span>
        <span class="home-text34" @click="scrollToContact()">Contact</span>
      </div>
      <div class="home-divider2"></div>
      <div class="home-container23">
        <span class="home-text35 Body2">© 2023 All rights reserved</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  methods: {
    scrollToContact() {
      if (this.$route.path !== '/contact') {
        this.$router.push({name: 'Contact'})
      }
    },
    scrollToAbout() {
      if (this.$route.path !== '/') {
        this.$router.push({name: 'Home'})
      }
    },
  }
}
</script>

<style scoped>

.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image10 {
  object-fit: cover;
  width: 100%;
  max-width: 380px;
}
.home-container22 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.home-text33 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.home-text33:hover {
  color: var(--dl-color-turquoise-default);
  cursor: pointer;
}
.home-text34 {
  transition: 0.3s;
  white-space: nowrap;
  cursor: pointer;
}
.home-text34:hover {
  color: var(--dl-color-turquoise-default);
}
.home-divider2 {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.home-container23 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text35 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}


@media(max-width: 767px) {


  .home-container22 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-container23 {
    flex-direction: column;
  }
  .home-text35 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}

</style>
