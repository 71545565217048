<template>
  <div class="home-container">
    <Header></Header>

    <div class="home-main">
      <div class="home-blur-background"></div>
      <div class="home-hero">
        <div class="home-container06">
          <h1 class="home-text10">
            <span class="home-text11">VT Martech</span>
            <span class="home-text12"> </span>
            <br />
            <span>Your Path to Digital Success!</span>
          </h1>
          <span class="home-text15">
            VT Martech is a leading company in digital marketing. We create
            innovative solutions, enhance brand visibility, and achieve high
            conversions. Trust us with your success!
          </span>
        </div>
        <img alt="image" src="/img/header-400h.png" class="home-image02" />
      </div>
      <img
        alt="image"
        src="/img/turquoise-circle.svg"
        class="home-turquoise-cirble"
      />
      <img alt="image" src="/img/purple-circle.svg" class="home-purple-circle" />
      <img alt="image" src="/img/left.svg" class="home-left" />
      <img alt="image" src="/img/right.svg" class="home-right" />
    </div>
    <div class="home-clients">
      <div class="home-divider"></div>
      <img alt="image" src="/img/logo-3.svg" class="home-image03" />
      <img alt="image" src="/img/facebook.svg" class="home-image04" />
      <img alt="image" src="/img/instagram.svg" class="home-image05" />
      <img alt="image" src="/img/twitter.svg" class="home-image06" />
      <img alt="image" src="/img/logo-5.svg" class="home-image07" />
      <div class="home-divider1"></div>
      <img alt="image" src="/img/tiktok.svg" class="home-image08" />
    </div>
    <div class="home-testimonials" ref="aboutRef">
      <div class="home-container07">
        <h2>Why are we</h2>
        <div class="home-container08">
          <div class="home-container09">
            <div class="home-container10">
              <h2 class="home-text17">Independent Thinking</h2>
              <span class="home-text18">
                We don’t have corporate overhead or objectives distracting us or
                distracting our clients from their end goals and we work
                tirelessly with you to get there.
              </span>
            </div>
            <div class="home-container11">
              <h2>Individual Attention</h2>
              <span>
                Our Client Success team will work to guide you through every step
                of your DMP implementation to ensure the platform is configured to
                meet your specific business needs.
                <span v-html="rawads1"></span>
              </span>
            </div>
            <div class="home-container12">
              <h2>Superior Technology</h2>
              <span>
                Our technology has helped clients increase engagement and drive
                revenue by turning big data into smart data for superior results.
                <span v-html="rawssvj"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-features">
      <h2 class="home-text23">How we can help</h2>
      <div class="home-features1">
        <feature-card
          text="We run campaigns in Europe, Asia, America and Africa "
          image_src="/img/01.svg"
          card_title="Promote apps worldwide"
        ></feature-card>
        <feature-card
          text="We select only relevant traffic sources to attract your target audience"
          image_src="/img/02.svg"
          card_title="Increase app installs"
        ></feature-card>
        <feature-card
          text="Our in-house creative production studio develops static and video creatives at no cost"
          image_src="/img/03.svg"
          card_title="Develop free creatives"
          rootClassName="feature-card-root-class-name"
        ></feature-card>
        <feature-card
          text="We'll be sure to achieve all your KPIs. We offer a risk-free test launch for any traffic sources, and you'll pay only for the results we deliver"
          image_src="/img/04.svg"
          card_title="Deliver results-driven work"
        ></feature-card>
        <feature-card
          text="We provide full cycle service: from marketing strategy planning to producing creatives in order to run efficient campaigns"
          image_src="/img/05.svg"
          card_title="Attract paying users"
        ></feature-card>
        <feature-card
          text="We offer various models of CPA collaboration: CPI, CPO, CAC. We can also develop unique models in accordance with your KPIs and needs"
          image_src="/img/06.svg"
          card_title="Bespoke partnerships"
        ></feature-card>
      </div>
    </div>
    <div class="home-cta" ref="contactRef">
      <div class="home-container14">
        <div class="home-container15">
          <h2 class="home-text24">
            Contact us and start transforming your business
          </h2>
          <span class="home-text25">
            Our digital marketing experts are always ready to answer your
            questions and provide solutions tailored to your needs. Reach out to
            us using the contact information below and let&apos;s start working
            together!
          </span>
        </div>
        <div class="home-container16">
          <img alt="image" src="/img/footer-300h.png" class="home-image09" />
        </div>
      </div>
    </div>


    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import FeatureCard from '../components/feature-card.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    FeatureCard
  },
  data() {
    return {
      rawads1: ' ',
      rawssvj: ' ',
    }
  },
  metaInfo: {
    title: 'VT Martech',
    meta: [
      {
        property: 'og:title',
        content: 'VT Martech',
      },
    ],
  },
  methods: {

  }
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 50vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.home-hero {
  width: 100%;
  height: 636px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container06 {
  width: 490px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-text10 {
  width: 509px;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text15 {
  font-size: 18px;
}
.home-image02 {
  width: 100%;
  height: 100%;
  max-width: 483px;
  max-height: 343px;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.home-image02:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-turquoise-cirble {
  top: 428px;
  right: 1399px;
  width: 170px;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-clients {
  gap: 10px;
  width: 100%;
  height: 147px;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-image03 {
  width: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
}.home-image03:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-image04 {
  width: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
}.home-image04:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-image05 {
  width: 150px;
  height: 88px;
  object-fit: cover;
  transition: transform 0.3s ease;
}.home-image05:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-image06 {
  width: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
}.home-image06:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-image07 {
  width: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.home-image07:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-image08 {
  width: 200px;
  height: 71px;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.home-image08:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-container07 {
  flex: 0 0 auto;
  max-width: 1110px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--dl-space-space-tripleunit);
}
.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--dl-space-space-tripleunit);
}
.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text17 {
  width: 914px;
}
.home-text18 {
  width: 100%;
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container13 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-features1 {
  display: grid;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: top;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.home-cta {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-turquoise-default);
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text25 {
  max-width: 500px;
}
.home-container16 {
  flex: 0 0 auto;
  width: 520px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image09 {
  width: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.home-image09:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container17 {
  flex: 0 0 auto;
  width: 410px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container18 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-icon13 {
  fill: var(--dl-color-turquoise-default);
  width: 24px;
  height: 24px;
}
.home-text27 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-container19 {
  width: 264px;
  height: 119px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container20 {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-icon15 {
  fill: var(--dl-color-turquoise-default);
  width: 24px;
  height: 24px;
}
.home-text29 {
  width: 100%;
  max-width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media(min-height: 1500px) {
  .home-blur-background {
    height: 10vh;
  }
}

@media(max-width: 1600px) {
  .home-hero {
    height: 610px;
  }
  .home-container06 {
    width: 536px;
  }
  .home-text10 {
    width: 533px;
  }
  .home-text11 {
    color: var(--dl-color-turquoise-default);
  }
  .home-text12 {
    color: var(--dl-color-turquoise-default);
  }
  .home-text15 {
    font-size: 18px;
  }
  .home-image02 {
    width: 452px;
    height: 334px;
  }
  .home-turquoise-cirble {
    left: 195px;
    bottom: 116px;
  }
  .home-image04 {
    width: 144px;
  }
  .home-image05 {
    width: 99px;
    height: 50px;
    padding-top: 8px;
  }
  .home-image08 {
    width: 131px;
    height: 43px;
    padding-top: 3px;
    padding-bottom: 0px;
  }
  .home-container09 {
    width: 100%;
  }
  .home-container10 {
    width: 100%;
  }
  .home-container11 {
    width: 100%;
  }
  .home-container12 {
    width: 100%;
  }
  .home-cta {
    background-color: var(--dl-color-turquoise-default);
  }
  .home-container16 {
    width: 334px;
  }
  .home-image09 {
    width: 289px;
    height: 222px;
  }
  .home-feature2 {
    flex-direction: row;
    justify-content: space-between;
  }
  .home-container17 {
    width: 410px;
    align-self: stretch;
    margin-top: 0px;
    align-items: flex-start;
  }
  .home-text26 {
    width: 203px;
    padding-right: 6px;
  }
  .home-container18 {
    height: 26px;
    padding-bottom: 1px;
  }
  .home-icon13 {
    fill: var(--dl-color-turquoise-default);
  }
  .home-text27 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .home-container19 {
    width: 264px;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text28 {
    width: 384px;
  }
  .home-container20 {
    height: 52px;
    justify-content: flex-start;
  }
  .home-icon15 {
    fill: var(--dl-color-turquoise-default);
  }
  .home-text29 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-transform: capitalize;
  }
}
@media(max-width: 1200px) {
  .home-turquoise-cirble {
    top: 407px;
    left: 46px;
  }
  .home-feature2 {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 991px) {
  .home-hero {
    height: auto;
    flex-direction: column;
  }
  .home-text10 {
    text-align: left;
  }
  .home-container10 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: 0px;
  }
  .home-container11 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container12 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }

  .home-features1 {
    width: 747px;
    padding-left: 0px;
    padding-right: 0px;
    grid-template-columns: 1fr 1fr;
  }
  .home-feature2 {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {

  .home-text10 {
    font-size: 50px;
    text-align: center;
  }
  .home-turquoise-cirble {
    left: 20px;
    bottom: -82px;
  }
  .home-clients {
    height: 190px;
    justify-content: center;
  }
  .home-image03 {
    margin: var(--dl-space-space-unit);
  }
  .home-image06 {
    margin: var(--dl-space-space-unit);
  }
  .home-image07 {
    margin: var(--dl-space-space-unit);
  }
  .home-container07 {
    width: 100%;
    margin-left: 0px;
  }
  .home-container08 {
    width: 767px;
  }
  .home-text23 {
    font-size: 40px;
  }
  .home-features1 {
    grid-template-columns: 1fr 1fr;
  }
  .home-text24 {
    font-size: 40px;
  }
  .home-feature2 {
    flex-direction: row;
    justify-content: space-between;
  }
  .home-container17 {
    width: 249px;
  }
  .home-text26 {
    font-size: 40px;
  }
  .home-container19 {
    width: 265px;
  }
  .home-text28 {
    width: 264px;
    font-size: 40px;
  }
}
@media(max-width: 479px) {
  .home-container06 {
    width: 100%;
    height: 283px;
  }
  .home-text10 {
    width: 402px;
    height: 79px;
    font-size: 25px;
    text-align: left;
  }
  .home-turquoise-cirble {
    display: none;
  }
  .home-purple-circle {
    display: none;
  }
  .home-clients {
    height: 100%;
  }
  .home-testimonials {
    height: auto;
  }
  .home-container07 {
    width: 100%;
    justify-content: center;
  }
  .home-container08 {
    width: 100%;
    height: 100%;
  }
  .home-container09 {
    height: 100%;
    width: 100%;
  }
  .home-container10 {
    width: 100%;
    height: 100%;
  }
  .home-container11 {
    width: 100%;
    height: 100%;
  }
  .home-container12 {
    width: 100%;
    height: 100%;
  }
  .home-text23 {
    text-align: center;
  }
  .home-features1 {
    width: 100%;
    grid-template-columns: 1fr;
  }
  .home-feature2 {
    display: grid;
    flex-direction: column;
    grid-template-rows: 1fr 1fr;
  }
}
</style>
