<template>
  <div class="home-container">
    <Header></Header>
    

    <div class="home-main">
      <div class="home-blur-background"></div>
      <div class="home-hero">
        
        <div class="home-container06" style="gap: 20px;">

          <img alt="image" src="/img/logo2-1200w.png" class="home-image10" />
          
          <h2 class="home-text-header">VT-martech EMEA d.o.o. Beograd</h2>
          
          <!-- <div>
            <h2 class="home-text-header">Id: </h2>
            <span class="home-text29">21794597</span>
          </div> -->
          <!-- <h2 class="home-text-header" style="padding-top: 15px;">Id: 21794597</h2> -->
          
          <div>
            <h2 class="home-text-header">PIB/TIN: </h2>
            <span class="home-text29">113053615</span>
          </div>



          <div class="home-container17">
            <h2 class="home-text-header">Email:</h2>
            <div class="home-container18">
              <svg viewBox="0 0 1024 1024" class="home-icon13">
                <path
                  d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                ></path>
              </svg>
              <a class="home-text27" href="mailto:hello@vtmartech.com">hello@vtmartech.com</a>
            </div>
          </div>
          
          <div class="home-container19">
            <h2 class="home-text-header">Address:</h2>
            <div class="home-container20">
              <svg viewBox="0 0 1024 1024" class="home-icon15">
                <path
                  d="M512 490q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 86q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z"
                ></path>
              </svg>
              <span class="home-text29">
                <span>BEOGRAD, BEOGRAD-NOVI, DANILA LEKIĆA-ŠPANCA 1</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <img
        alt="image"
        src="/img/turquoise-circle.svg"
        class="home-turquoise-cirble"
      />
      <img alt="image" src="/img/purple-circle.svg" class="home-purple-circle" />
      <img alt="image" src="/img/left.svg" class="home-left" />
      <img alt="image" src="/img/right.svg" class="home-right" />
    </div>



    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      rawads1: ' ',
      rawssvj: ' ',
    }
  },
  metaInfo: {
    title: 'VT Martech | Contact',
    meta: [
      {
        property: 'og:title',
        content: 'VT Martech',
      },
    ],
  },
  methods: {

  }
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;    
  justify-content: space-between;
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 50vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.home-hero {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container06 {
  width: 490px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-text10 {
  width: 509px;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text15 {
  font-size: 18px;
}
.home-image02 {
  width: 100%;
  height: 100%;
  max-width: 483px;
  max-height: 343px;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.home-image02:hover {
  /* Увеличиваем масштаб изображения на 2% */
  transform: scale(1.02);
}
.home-turquoise-cirble {
  top: 428px;
  right: 1399px;
  width: 170px;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}

.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container17 {
  flex: 0 0 auto;
  /* width: 410px; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container18 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-icon13 {
  fill: var(--dl-color-turquoise-default);
  width: 24px;
  height: 24px;
}
.home-text27 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
  font-size: 20px;
}
.home-container19 {
  /* width: 264px; */
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container20 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-icon15 {
  fill: var(--dl-color-turquoise-default);
  width: 24px;
  height: 24px;
}
.home-text29 {
  width: 100%;
  max-width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image10 {
  object-fit: cover;
  width: 100%;
}
.home-container22 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.home-text33 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.home-text33:hover {
  color: var(--dl-color-turquoise-default);
  cursor: pointer;
}
.home-text34 {
  transition: 0.3s;
  white-space: nowrap;
  cursor: pointer;
}
.home-text34:hover {
  color: var(--dl-color-turquoise-default);
}
.home-divider2 {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.home-container23 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text35 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
@media(min-height: 1500px) {
}

@media(max-width: 1600px) {
  .home-feature2 {
    flex-direction: row;
    justify-content: space-between;
  }
  .home-container17 {
    width: 410px;
    align-self: stretch;
    margin-top: 0px;
    align-items: flex-start;
  }
  .home-text26 {
    width: 203px;
    padding-right: 6px;
  }
  .home-container18 {
    height: 26px;
    padding-bottom: 1px;
  }
  .home-icon13 {
    fill: var(--dl-color-turquoise-default);
  }
  .home-text27 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .home-container19 {
    width: 100%;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text28 {
    width: 384px;
  }
  .home-container20 {
    height: 100%;
    justify-content: flex-start;
  }
  .home-icon15 {
    fill: var(--dl-color-turquoise-default);
  }
  .home-text29 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-transform: capitalize;
  }
}
@media(max-width: 1200px) {

  .home-feature2 {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 991px) {

  .home-feature2 {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {

  .home-feature2 {
    flex-direction: row;
    justify-content: space-between;
  }
  .home-container17 {
    width: 249px;
  }
  .home-text26 {
    font-size: 40px;
  }
  .home-container19 {
    width: 100%;
  }
  .home-text28 {
    width: 264px;
    font-size: 40px;
  }
  .home-container22 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-container23 {
    flex-direction: column;
  }
  .home-text35 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  
  .home-feature2 {
    display: grid;
    flex-direction: column;
    grid-template-rows: 1fr 1fr;
  }
}

.home-text-header {
  font-size: 25px;
}
</style>
