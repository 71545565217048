<template>
    <div class="feature-card-container" v-bind:class="rootClassName">
      <img :alt="image_alt" :src="image_src" class="feature-card-image" />
      <h5 class="Headline5 feature-card-text">{{ card_title }}</h5>
      <span class="feature-card-text1">{{ text }}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FeatureCard',
    props: {
      rootClassName: String,
      card_title: {
        type: String,
        default: 'Title',
      },
      text: {
        type: String,
        default:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.',
      },
      image_src: {
        type: String,
        default: '/01.svg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
    },
  }
  </script>
  
  <style scoped>
  .feature-card-container {
    gap: 10px;
    width: 320px;
    margin: var(--dl-space-space-halfunit);
    display: grid;
    position: relative;
    align-self: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    flex-direction: column;
    grid-template-rows: 30% 10% 58%;
    transition: transform 0.3s ease;
  }
  .feature-card-image {
    width: var(--dl-size-size-doubleunit);
    height: var(--dl-size-size-doubleunit);
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  .feature-card-image:hover {
    /* Увеличиваем масштаб изображения на 2% */
    transform: scale(1.02);
  }
  .feature-card-container:hover {
    background-color: #F2FFFF;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  .feature-card-text1 {
    /* max-width: 240px; */
    text-align: center;
  }
  
  @media(max-width: 1600px) {
    .feature-card-container {
      height: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .feature-card-text {
      width: 320px;
      align-self: center;
      text-align: left;
      align-items: center;
      align-content: center;
    }
    .feature-card-text1 {
      width: 100%;
      max-width: auto;
      text-align: left;
    }
  }
  @media(max-width: 1200px) {
    .feature-card-container {
      width: auto;
      max-width: 320px;
    }
    .feature-card-root-class-name {
      width: 320px;
    }
  }
  @media(max-width: 991px) {
    .feature-card-container {
      width: auto;
      height: 219px;
    }
  }
  @media(max-width: 767px) {
    .feature-card-container {
      height: auto;
    }
    .feature-card-text {
      width: auto;
      /* max-width: 240px; */
    }
  }
  </style>
  